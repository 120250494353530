<template>
  <b-field :label="label"
           :type="!!error? 'is-danger': ''"
           :message="error">
    <b-select :placeholder="placeholder"
              :value="value"
              @input="val => $emit('input', val)"
              expanded>
      <option v-for="item in states"
              :value="item[primaryKey]">
        {{ item[optionText] }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "SelectState",
  props: {
    primaryKey: {
      type: String,
      default: 'code'
    },
    optionText: {
      type: String,
      default: 'name'
    },
    label: String,
    placeholder: String,
    value: {},
    error: {}
  },
  data() {
    return {
      loading: false,
      apiUrl: 'main/states',
    }
  },
  computed: {
    ...mapState({
      states: state => state.main.states
    })
  },
}
</script>

<style scoped>

</style>