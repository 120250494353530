<template>
  <div class="columns is-multiline">
    <div class="column is-6">
      <b-field label="Versión CFDI">
        <b-select
            expanded
            placeholder="Facturar usando la versión  3.3 (default)"
            v-model="data.sat_version">
          <option value="3.3" v-if="false">Facturar usando la versión  3.3 (default)</option>
          <option value="4.0">Facturar usando la versión 4.0</option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-7">
      <form-input label="Nombre o Razón Social"
                  v-model="data.fiscal_name"
                  :error="errors.fiscal_name"
      >
      </form-input>
    </div>
    <div class="column is-5">
      <b-field label="RFC" grouped>
        <form-input label="RFC"
                    v-model="data.rfc"
                    @input="updateRfc"
                    custom-class="is-uppercase"
                    :error="errors.rfc"
        >
        </form-input>
        <search-rfc-patient v-if="!edit" :search="data.rfc"
                            :disbled="!!data.rfc"
                            @setData="val => $emit('setTaxData', val)">
        </search-rfc-patient>
      </b-field>
    </div>
    <div class="column is-12">
      <b-field label="Régimen Fiscal" >
        <b-select
            expanded
            v-model="data.sat_regimen">
          <option value="">Seleccionar Regimen Fiscal</option>
          <option :value="item.code" v-for="item in fiscalRegimens">{{ item.code }} - {{ item.name }}</option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-12" v-if="showCFDIUse">
      <b-field label="Uso de CFDI" >
        <b-select
            v-model="data.use"
            expanded
            required>
          <option :value="item.code" v-for="item in fiscalCfdiUses">{{ item.code }} - {{ item.name }}</option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-6">
      <form-input label="Correo"
                  type="email"
                  v-model="data.email"
                  :error="errors.email"
      >
      </form-input>

    </div>
    <div class="column is-6">
      <form-input label="Confirmar Correo"
                  type="email"
                  v-model="data.email_confirmation"
                  :error="errors.email_confirmation"
      >
      </form-input>
    </div>
    <div class="column is-6" v-if="false">
      <form-input label="Calle"
                  v-model="data.street"
                  :error="errors.street"
      >
      </form-input>
    </div>
    <div class="column is-3" v-if="false">
      <form-input label="Número Exterior"
                  v-model="data.street_number"
                  :error="errors.street_number"
      >
      </form-input>
    </div>
    <div class="column is-3" v-if="false">
      <b-field label="Número Interior">
        <b-input v-model="data.street_number_other">
        </b-input>
      </b-field>
    </div>
    <div class="column is-6" v-if="false">
      <form-input label="Colonia"
                  v-model="data.location"
                  :error="errors.location"
      >
      </form-input>
    </div>
    <div class="column is-6" v-if="false">
      <form-input label="Localidad"
                  v-model="data.village"
                  :error="errors.village"
      >
      </form-input>
    </div>
    <div class="column is-6" v-if="false">
      <select-state label="Estado"
                    v-model="data.state"
                    :error="errors.state">
      </select-state>
    </div>
    <div class="column is-6" v-if="false">
      <select-city label="Municipio"
                   v-model="data.city"
                   :state="state"
                   :error="errors.city">
      </select-city>
    </div>
    <div class="column is-6">
      <form-input label="Código Postal"
                  type="number"
                  step="1"
                  v-model="data.postal_code"
                  :error="errors.postal_code"
      >
      </form-input>
    </div>
    <div class="column is-6">
      <form-input label="Whatsapp (Opcional)"
                  type="text"
                  :maxlength="10"
                  :minlength="10"
                  :pattern='"^\\d{10}$"'
                  v-model="data.phone"
                  @input.native="(e) => handleInput(e, 'phone')"
                  :error="errors.phone"
                  :title="`Formato: 10 dígitos`"
      >
      </form-input>
    </div>
    <div class="column is-6" v-if="false">
      <select-country label="Paìs"
                      v-model="data.country"
                      :error="errors.country">
      </select-country>
    </div>
  </div>
</template>

<script>
import SelectState from "../partials/SelectState";
import SelectCity from "../partials/SelectCity";
import SearchRfcPatient from "./SearchRfcPatient";
import FormInput from "@/components/partials/FormInput";
import SelectCountry from "@/components/partials/SelectCountry";
export default {
  name: "FormPatientTaxData",
  components: {SelectCountry, FormInput, SearchRfcPatient, SelectCity, SelectState},
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    edit: Boolean,
    errors: {
      type: Object,
      default() {
        return {}
      }
    },
    showCFDIUse: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    state() {
      let state = this.$store.state.main.states.find(el => this.data.state === el.code);
      return state ? state.id : '0';
    },
    fiscalCfdiUses() {
      return this.$store.state.main.fiscalCfdiUses || [];
    },
    fiscalRegimens() {
      return this.$store.state.main.satRegimenes || [];
    }
  },
  mounted() {
    this.data.sat_version = '4.0';
  },
  methods: {
    handleInput(el, type){
      if(type === 'phone'){
        this.errors.phone = this.getCustomErrorMessage(el.originalTarget);
      }
    },
    getCustomErrorMessage(input){
      if(!input.validationMessage){
        return '';
      }
      if(input.title && !input.validationMessage.endsWith(input.title + '.')){
        return input.validationMessage + ' ' + input.title;
      }
      return input.validationMessage;
    },
    updateRfc(){
      this.data.rfc = this.data.rfc.toString().toUpperCase();
      if(this.data.rfc.toString().length === 12){
        this.data.use = 'G03';
      }else{
        this.data.use = 'D01';
      }
    }
  }
}
</script>

<style scoped>

</style>
