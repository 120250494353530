<template>
  <p class="control">
    <button class="button is-info"
            type="button"
            @click="onSearchTaxData"
            :disabled="disabled">
      <b-icon icon="search"></b-icon>
    </button>
  </p>
</template>

<script>
export default {
  name: "SearchRfcPatient",
  props: {
    search: String,
    disabled: Boolean
  },
  methods: {
    async onSearchTaxData() {
      this.$loader.show();
      await this.$http.get(`patient-tax-datum`,  {
        params: {
          rfc: this.search,
          perPage: 1,
        }
      })
          .then(({data}) => {
            let items = data.data || [];
            if (items.length) {
              let taxData = items[0];
              delete taxData.code;
              this.$emit('setData', taxData || {});
            }
          }, ({response}) => {
            let data = response.data;
          });
      this.$loader.hide();
    }
  }
}
</script>

<style scoped>

</style>