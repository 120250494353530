<template>
  <b-field :label="label"
           :type="!!error? 'is-danger': ''"
           :message="error">
    <b-select :placeholder="placeholder"
              :value="value"
              @input="val => $emit('input', val)"
              expanded>
      <option v-for="item in data"
              :value="item[primaryKey]">
        {{ item[optionText] }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "SelectCity",
  props: {
    primaryKey: {
      type: String,
      default: 'name'
    },
    optionText: {
      type: String,
      default: 'name'
    },
    label: String,
    placeholder: String,
    value: {},
    state: String | Number,
    error: {}
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      cities: state => state.main.cities
    }),
    data() {
      return this.cities.filter(el => String(this.state) === el.state_id)
    }
  },
}
</script>

<style scoped>

</style>