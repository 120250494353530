<template>
  <b-field :label="label"
           :label-position="labelPosition"
           :type="!!error?'is-danger':''"
           :message="error">
    <b-input :type="type"
             :custom-class="customClass"
             :readonly="readonly"
             :required="required"
             :disabled="disabled"
             :min="min"
             :max="max"
             :maxlength="maxlength"
             :minlength="minlength"
             :password-reveal="passwordReveal"
             :id="id"
             :icon="icon"
             :value="value"
             :pattern="pattern"
             @input="val => $emit('input', val)"
             @input.native="handleInput"
             :title="title"
             @change="val => $emit('input', val)">
    </b-input>
  </b-field>
</template>

<script>
export default {
  name: "FormInput",
  props: {
    id: {},
    label:String,
    labelPosition:String,
    placeholder:String,
    customClass: String,
    size:String,
    type:String,
    icon:String,
    required: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    step: Number|String,
    min: Number|String,
    max: Number|String,
    maxlength: Number|String,
    minlength: Number|String,
    passwordReveal: Boolean,
    error: {},
    value: {},
    pattern: String,
    title:''
  },
  methods: {
    handleInput(e){
      this.$emit('input.native', e);
    }
  }

}
</script>

<style scoped>

</style>