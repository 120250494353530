<template>
  <form @submit.prevent="onUpdate">
    <form-patient-tax-data :data="form"
                           edit
                           :showCFDIUse="false"
                           @setTaxData="val => form = val"
                           :errors="errors">
    </form-patient-tax-data>
    <b-field>
      <button class="button is-warning" type="submit">
        Actualizar Datos Fiscales
      </button>
    </b-field>
  </form>
</template>

<script>
import FormPatientTaxData from "@/components/patient/FormPatientTaxData";
export default {
  name: "UpdatePatientTaxDataForm",
  components: {FormPatientTaxData},
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    patient: String
  },
  data() {
    return {
      form: {},
      errors: {},
      apiUrl: 'patient-tax-datum'
    }
  },
  mounted() {
    this.form = this.data || {}
  },
  methods: {
    async onUpdate() {
      this.$loader.show();
      await this.updateData(this.patient, this.form)
          .then(data => {
            this.form = data.data
            this.$toast.success(data.message);
          }, error => {
            this.$toast.error(error.message);
          });
      this.$loader.hide();
    }
  }
}
</script>

<style scoped>

</style>
